import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-ruhpolding-preview.jpg'
import presseImage1 from '@images/presse/Festl_Tour_Impressionen_1.jpg'
import presseImage2 from '@images/presse/Festl_Tour_Impressionen_2.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-ruhpolding.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Bühne frei für bayerische Beats, köstliche Schmankerl und mitreißende Stimmung!
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Die Alpin FM Festl Tour mit ERDINGER Brauhaus zu Gast in Ruhpolding
                  </strong>
                </p>
                <p>
                  Erding, Juli 2023<br />
                  Die Alpin FM Festl Tour mit ERDINGER Brauhaus geht in die nächste Runde. Nach dem grandiosen Auftakt 
                  in Bad Reichenhall und der perfekten Fortsetzung in Oberaudorf macht die Tour Halt in Ruhpolding.
                </p>
                <p>
                  Am Sonntag, den 30. Juli, wird der Platz rund ums Café Chiemgau (Hauptstraße 59) im Herzen 
                  Ruhpoldings ab 14 Uhr zur angesagten Party-Location. Die bayerischen Top-Bands Uferlos, 
                  Monaco F, Gsindl und DeSchoWieda spielen ordentlich auf und bringen die Stimmung zum 
                  Kochen. Doch nicht nur musikalisch ist einiges geboten, auch für Speis und Trank ist bestens 
                  gesorgt. Für süße Genussmomente verwöhnt Familie Heigermoser vom Café Chiemgau die 
                  Gäste mit hausgemachten Kuchen und Torten. Deftige Grill-Schmankerl gibt’s von der
                  Bergwacht Ruhpolding. Die Wetzstoana schenken gemeinsam mit Getränke Gimpl ERDINGER 
                  Brauhaus Spezialitäten sowie alkoholfreie Getränke aus – da bleibt keine durstige Kehle 
                  trocken. Also: vorbeikommen, mitfeiern und genießen! Der Eintritt ist frei. Alpin FM, alle 
                  Bands, Partner und ERDINGER Brauhaus freuen sich auf zahlreiche Gäste.
                </p>
                <p>
                  Nach der Station in Ruhpolding am Sonntag, den 30. Juli, folgt dann am Sonntag, den 27. 
                  August, noch das große Finale in Erding.
                </p>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <ImageSource>Bildquelle: Darius Kappes / ERDINGER Weißbräu.</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage1} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
                <ContentRowImage>
                  <img src={presseImage2} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
